@use '@angular/material' as mat;

@include mat.core();
@import '~quill/dist/quill.snow.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

$sbs-primary: mat.define-palette(mat.$red-palette, A700);
$sbs-accent: mat.define-palette(mat.$gray-palette, 500);

$sbs-theme: mat.define-light-theme((
  color: (
    primary: $sbs-primary,
    accent: $sbs-accent,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($sbs-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-icon {
  vertical-align: middle;
}
